<template>
  <v-card v-if="request">
    <v-card-title>
      <v-btn icon @click="$router.push('/requests')">
        <v-icon>mdi-arrow-left</v-icon> </v-btn
      >Détails de la demande</v-card-title
    >
    <v-card-text class="px-15 pt-5 pb-15">
      <v-text-field
        outlined
        dense
        label="Nom"
        :value="request.lastname"
        disabled
      ></v-text-field>
      <v-text-field
        outlined
        dense
        label="Prénom"
        :value="request.firstname"
        disabled
      ></v-text-field>
      <v-text-field
        outlined
        dense
        label="E-mail"
        :value="request.email"
        disabled
      ></v-text-field>
      <v-select
        outlined
        dense
        label="Type de compte"
        :items="types"
        :value="request.type"
        disabled
      ></v-select>
      <v-text-field
        outlined
        dense
        label="N° de compte"
        :value="request.number"
        disabled
      ></v-text-field>
      <v-text-field
        outlined
        dense
        label="Nom du titulaire"
        :value="request.holder"
        disabled
      ></v-text-field>
      <v-text-field
        outlined
        dense
        label="Date de création"
        :value="formatDate(request.createdAt)"
        disabled
      ></v-text-field>
      <v-text-field
        outlined
        dense
        label="Date de mise à jour"
        :value="formatDate(request.updatedAt)"
        disabled
      ></v-text-field>
      <v-btn
        outlined
        block
        color="grey darken-1"
        @click="downloadFile(request.card)"
        :loading="downloading"
      >
        Scan de la carte
        <v-icon right dark> mdi-cloud-download </v-icon>
      </v-btn>
      <v-select
        :items="statuses"
        v-model="request.status"
        outlined
        dense
        label="Statut"
        class="mt-6"
        :disabled="submitting"
      ></v-select>
      <v-btn
        outlined
        block
        color="grey darken-1"
        :disabled="submitting"
        @click="updateStatus"
        >Mettre à jour le statut</v-btn
      >
    </v-card-text>
    <v-snackbar v-model="snackbar.visible" :color="snackbar.color">
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar.visible = false">
          Fermer
        </v-btn>
      </template>
    </v-snackbar>
  </v-card>
</template>

<script>
import axios from "axios";
import fileDownload from "js-file-download";

export default {
  props: ["formatDate"],
  async mounted() {
    const { data: request } = await axios({
      method: "get",
      url: `${process.env.VUE_APP_API_URI}/admin/requests/${this.$route.params._id}`,
    });
    this.request = request;
  },
  data: () => ({
    request: undefined,
    types: ["--", "Postfinance", "Banque"],
    statuses: [
      { text: "À traiter", value: "pending" },
      { text: "Traitée", value: "complete" },
    ],
    downloading: false,
    submitting: false,
    snackbar: {
      visible: false,
      color: "",
      text: "",
    },
  }),
  methods: {
    async updateStatus() {
      this.submitting = true;
      const { data: request } = await axios({
        method: "patch",
        url: `${process.env.VUE_APP_API_URI}/admin/requests/${this.$route.params._id}`,
        data: { ...this.request },
      });
      this.request = request;
      this.submitting = false;
      this.snackbar = {
        visible: true,
        color: "success",
        text: "Statut mis à jour",
      };
    },
    downloadFile: async function (filename) {
      this.downloading = true;
      const { data } = await axios({
        method: "get",
        url: `${process.env.VUE_APP_API_URI}/files/${filename}`,
        responseType: "arraybuffer",
      });
      fileDownload(data, filename);
      this.downloading = false;
    },
  },
};
</script>
