<template>
  <v-app>
    <v-app-bar app color="grey darken-2" dark>
      <img
        src="https://storage.googleapis.com/visual-identity/logo/2020-slim.svg"
        alt="Logo HEIG-VD"
        height="70%"
        class="logo"
      />
      <div class="d-flex align-center ml-5">
        DEMANDES DE MISE À JOUR DU COMPTE SALAIRE
      </div>
      <v-spacer></v-spacer>
      <user-profile />
    </v-app-bar>
    <v-main>
      <v-container>
        <v-row justify="center">
          <v-col cols="12" sm="12" lg="8" class="mt-10"
            ><router-view :formatDate="formatDate"
          /></v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import UserProfile from "@/components/UserProfile";
import { format, parseISO } from "date-fns";

export default {
  name: "App",
  components: { UserProfile },
  methods: {
    formatDate: function (value) {
      return format(parseISO(value), "dd.MM.yyyy HH:mm");
    },
  },
};
</script>

<style>
.separator {
  display: inline-block;
}
</style>
