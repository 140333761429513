<template>
  <v-card>
    <v-card-title>
      Demandes
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Rechercher"
        single-line
        hide-details
        outlined
        dense
      ></v-text-field>
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="requests"
      :options.sync="options"
      :server-items-length="total"
      :loading="loading"
      class="elevation-1"
      locale="fr-CH"
      :search="search"
      :footer-props="{
        itemsPerPageOptions: [10, 30, 50],
        itemsPerPageText: 'Élements par page',
      }"
    >
      <template v-slot:body="{ items }">
        <tbody class="text-left">
          <tr
            v-for="item in items"
            :key="item._id"
            @click="$router.push(`/requests/${item._id}`)"
          >
            <td>{{ item.lastname }}</td>
            <td>{{ item.firstname }}</td>
            <td>{{ item.email }}</td>
            <td>
              <v-chip
                color="grey"
                text-color="white"
                v-if="item.status === 'pending'"
                small
                >À traiter</v-chip
              >
              <v-chip
                small
                color="green"
                text-color="white"
                v-if="item.status === 'complete'"
                >Traitée</v-chip
              >
            </td>
            <td>{{ formatDate(item.createdAt) }}</td>
          </tr>
        </tbody>
      </template>
      <!-- eslint-disable-next-line vue/valid-v-slot justification: possible according to vuetify documentation -->
      <template v-slot:footer.page-text="{ pageStart, pageStop, itemsLength }"
        >{{ pageStart }}-{{ pageStop }} de {{ itemsLength }}</template
      >
    </v-data-table>
  </v-card>
</template>

<script>
import axios from "axios";
import { debounce } from "lodash";
export default {
  name: "Home",
  props: ["formatDate"],
  data: () => ({
    total: 0,
    requests: [],
    options: {},
    loading: false,
    headers: [
      {
        text: "Nom",
        value: "orderId",
        sortable: false,
      },
      {
        text: "Prénom",
        value: "value",
        sortable: false,
      },
      {
        text: "Type",
        value: "type",
        sortable: false,
      },
      {
        text: "Statut",
        value: "status",
        sortable: false,
      },
      {
        text: "Date de création",
        value: "createdAt",
        sortable: false,
      },
    ],
    search: "",
  }),
  methods: {
    async getDataFromApi() {
      this.loading = true;
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      const { data } = await axios({
        method: "get",
        url: `${process.env.VUE_APP_API_URI}/admin/requests?page=${page}&sortBy=${sortBy}&sortDesc=${sortDesc}&itemsPerPage=${itemsPerPage}&type=${this.type}`,
      });
      this.loading = false;
      let { docs: items } = data;
      const total = data.totalDocs;
      return {
        items,
        total,
      };
    },
  },
  watch: {
    options: {
      async handler() {
        const data = await this.getDataFromApi();
        this.requests = data.items;
        this.total = data.total;
      },
      deep: true,
    },
    search: debounce(async function (value) {
      this.loading = true;
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      const { data } = await axios({
        method: "get",
        url: `${process.env.VUE_APP_API_URI}/admin/requests?page=${page}&sortBy=${sortBy}&sortDesc=${sortDesc}&itemsPerPage=${itemsPerPage}&type=${this.type}&search=${value}`,
      });
      this.loading = false;
      let { docs: items } = data;
      const total = data.totalDocs;
      this.requests = items;
      this.total = total;
    }, 500),
  },
};
</script>
