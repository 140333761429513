import Vue from 'vue'
import VueRouter from 'vue-router'
import Requests from '@/views/Requests.vue'
import Details from '@/views/Details.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    redirect: "/requests",
  },
  {
    path: "/requests",
    name: "Requests",
    component: Requests,
  },
  {
    path: "/requests/:_id",
    name: "Request details",
    component: Details,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
